/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-space {
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: inline-flex;
}
.ant-space-vertical {
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
}
.ant-space-align-center {
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
}
.ant-space-align-start {
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
}
.ant-space-align-end {
  -webkit-align-items: flex-end;
     -moz-box-align: end;
          align-items: flex-end;
}
.ant-space-align-baseline {
  -webkit-align-items: baseline;
     -moz-box-align: baseline;
          align-items: baseline;
}
.ant-space-item:empty {
  display: none;
}
.ant-space-rtl {
  direction: rtl;
}

